@font-face {
  font-family: 'Brother-1816';
  src: url('./assets/fonts/Brother 1816/TipoType - Brother-1816-Regular.otf') format('opentype');
}
@font-face {
  font-family: 'Brother-1816';
  src: url('./assets/fonts/Brother 1816/TipoType - Brother-1816-Regular-Italic.otf') format('opentype');
  font-style: italic;
}
@font-face {
  font-family: 'Brother-1816';
  src: url('./assets/fonts/Brother 1816/TipoType - Brother-1816-Bold.otf') format('opentype');
  font-weight: bold;
}
@font-face {
  font-family: 'Brother-1816';
  src: url('./assets/fonts/Brother 1816/TipoType - Brother-1816-Book.otf') format('opentype');
  font-weight: 300;
}
@font-face {
  font-family: 'Brother-1816';
  src: url('./assets/fonts/Brother 1816/TipoType - Brother-1816-Black.otf') format('opentype');
  font-weight: 900;
}
@font-face {
  font-family: 'BellMTStd';
  src: url('./assets/fonts/Bell/BellMTStd-Regular.otf') format('opentype');
}
@font-face {
  font-family: 'BellMTStd';
  src: url('./assets/fonts/Bell/BellMTStd-Bold.otf') format('opentype');
  font-weight: bold;
}

@font-face {
  font-family: 'MarselisPro';
  src: url('./assets/fonts/MarselisPro/MarselisPro.woff2') format('woff2'),
    url('./assets/fonts/MarselisPro/MarselisPro.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}


@font-face {
  font-family: 'MarselisPro';
  src: url('./assets/fonts/MarselisPro/MarselisPro-Bold.woff2') format('woff2'),
    url('./assets/fonts/MarselisPro/MarselisPro-Bold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

[dir='rtl'] {
  line-height: 1.4;
}
[dir='rtl'] * {
  font-family: Brother-1816, Almarai, sans-serif;
  letter-spacing: normal !important;
}

html {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  font-family: Brother-1816, Almarai, Arial, sans-serif;
  font-size: 16px;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: black;
}

body.open-dialog {
  overflow: hidden;
}

#root {
  width: 100%;
  height: 100%;
}

*:focus {
  outline: none;
}

.dimmed {
  opacity: 0.25;
  cursor: initial !important;
}

.spinner {
  margin: 100px auto 0;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

.spinner#inContinueButton {
  margin: 0 auto;
}

.spinner>div {
  background-color: #aaa;
  height: 100%;
  width: 6px;
  display: inline-block;
  margin: 0 1px;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner#inContinueButton>div {
  background-color: #e6e6e6;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.di-swiper-container {
  max-width: 1366px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
}

.swiper-button-next.di-swiper-button-next {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), white);
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 130px;
  z-index: 10;
  background-size: unset;
  margin-top: unset;
}
.swiper-container-rtl .swiper-button-prev.di-swiper-button-prev {
  transform: scaleX(-1);
  right: 0;
  left: auto;
}

.swiper-button-prev.di-swiper-button-prev {
  background-image: linear-gradient(to left, rgba(255, 255, 255, 0), white);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 130px;
  z-index: 10;
  background-size: unset;
  margin-top: unset;
}
.swiper-container-rtl .swiper-button-next.di-swiper-button-next {
  transform: scaleX(-1);
  left: 0;
  right: auto;
}

.mafpay-card-field {
  width: 100%;
  box-sizing: border-box;
  height: 43px !important;
  border: solid 1px rgba(0, 184, 241, 0.5) !important;
  padding: 14px !important;
  font-family: Brother-1816, Arial, sans-serif !important;
  font-size: max(0.8125rem, 16px) !important;
  color: #1b1b1b !important;
  transition: all 0.25s cubic-bezier(0.165, 0.84, 0.44, 1);
  border-radius: 3px !important;
  outline: none;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent !important;
}

.mafpay-card-field::placeholder {
  color: rgba(0, 0, 0, 0.3) !important;
}

.mafpay-card-field:hover {
  border: 3px solid #00b8f1 !important;
  padding: 12px !important;
}

.mafpay-card-field:focus {
  border: 1px solid #00b8f1 !important;
  padding: 14px !important;
}
.mafpay-card-field:disabled {
  border: 1px solid rgba(27, 27, 27, 0.25) !important;
  padding: 14px !important;
}

.mafpay-card-field.card-holder-name {
  width: 310px;
}

.mafpay-card-field input {
  background-color: transparent !important;
  color: #1b1b1b !important;
  font-size: max(0.8125rem, 16px) !important;
}

.mafpay-card-field input::placeholder {
  color: rgba(0, 0, 0, 0.3) !important;
}

.submit-container button {
  width: 310px;
  height: 47px;
  font-family: Brother-1816, Arial, sans-serif;
  font-size: 0.8125rem;
  font-weight: bold;
  letter-spacing: 1.5px;
  text-align: center;
  cursor: pointer;
  padding: 0;
  border-radius: 3px;
  -webkit-tap-highlight-color: transparent;
  background-color: #2e3191;
  color: white;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.3);
  border: none;
  transition: all 0.25s cubic-bezier(0.165, 0.84, 0.44, 1);
  border: 1px solid transparent;
}
.submit-container button:focus {
  border: 1px solid #00b8f1;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.3);
  color: white;
}

.submit-container button:hover {
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.3);
  color: #00b8f1;
  background-color: #1e205e;
}

.submit-container button:active {
  color: #2e3191;
  border: none;
  background-color: white;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

[dir='rtl'] .slick-slide {
  float: left !important;
}

@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}
